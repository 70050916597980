<!--
 * @Description: 客户移交列表
 * @Author: ChenXueLin
 * @Date: 2021-10-08 09:19:15
 * @LastEditTime: 2022-07-15 15:54:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpEnum"
                placeholder="客户名称"
                title="客户名称"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                @filterChange="corpHandleFilter"
                virtual
                clearable
                remote
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="rollOutSalesman">
              <all-user-search
                v-model="searchForm.rollOutSalesman"
                clear
                placeholder="转出业务员"
                title="转出业务员"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                :showSlot="true"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="rollInSalesman">
              <all-user-search
                v-model="searchForm.rollInSalesman"
                clear
                placeholder="转入业务员"
                title="转入业务员"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                :showSlot="true"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdByName">
              <el-input
                v-model.trim="searchForm.createdByName"
                placeholder="创建人"
                title="创建人"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="applyTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="申请日期"
                ref="applyTime"
                v-model="searchForm.applyTime"
                title="申请日期"
                :picker-options="pickerOptions('searchForm.applyTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-Import_fill" title="导入" @click="importXlxs()"></i>
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
          @sort-change="sortChange"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :sortable="column.sortable"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'applyTime'">{{
                row[column.fieldName] && row[column.fieldName].slice(0, 10)
              }}</span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import allUserSearch from "@/components/allUserSearch";
import {
  getcorpShift,
  getCorpMsg,
  findDownList,
  exportCustomerHandOver
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";

import { e3Api } from "@/config/api";
const { E3_BL_CORPORATION = "" } = e3Api;
export default {
  name: "CustomerHandOverList",
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      activeName: "second",
      searchForm: {
        corpId: "", //公司名称
        rollOutSalesman: "", //转出业务员
        rollInSalesman: "", //转入业务员
        createdByName: "", //申请人
        applyTime: [],
        applyTimeStart: "", // 申请日期开始
        applyTimeEnd: "", // 申请日期结束
        pageIndex: 1,
        pageSize: 20,
        sortDir: "",
        sortIndex: ""
      },
      queryListAPI: getcorpShift,
      timePickerResetList: ["applyTime"], // base mixin的配置项 需要重置的timePicker refname
      corpEnum: [], // 客户列表枚举
      dingTalkTaskResultEnum: [], // 审核状态
      total: 0,
      columnData: [
        {
          fieldName: "documentNo",
          display: true,
          fieldLabel: "单据编号",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "rollOutSalesmanName",
          display: true,
          fieldLabel: "转出业务员",
          width: 120,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "rollInSalesmanName",
          display: true,
          fieldLabel: "转入业务员",
          width: 120,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "applyTime",
          display: true,
          fieldLabel: "申请时间",
          width: 120,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 120,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  components: {
    allUserSearch
  },
  computed: {},
  created() {
    this.queryList();
    this.initData();
    this.corpDownList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    // 创建开始时间
    "searchForm.applyTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.applyTime = this.searchForm.applyTime.reverse();
        }
        this.searchForm.applyTimeStart = val[0] || ""; // beizhu
        this.searchForm.applyTimeEnd = val[1] || "";
      }
    }
  },
  methods: {
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.corpDownList(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    async corpDownList(val, enumKey = "corpEnum") {
      try {
        this.corpLoading = true;
        let corpEnumRes = await getCorpMsg({
          pageIndex: 1,
          pageSize: 200,
          corpName: val
        });
        this[enumKey] = this.getFreezeResponse(corpEnumRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.corpLoading = false;
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        //获取枚举数据 客户类型 客户类别 客户状态 客户业务类别
        let promiseList = [findDownList(["dingTalkTaskResult"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.dingTalkTaskResultEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.dingTalkTaskResult"
        });
      } catch (error) {
        printError(error);
      }
    },
    handleLoadRollInSalesman: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val, 1);
      }
    }, 300),
    //点击头部新增
    handleAdd() {
      this.routerPush({
        name: "customerManage/customerHandOverDetail",
        params: {
          refresh: true,
          origin: this.$route.name
        }
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "corpshift_import",
          origin: this.$route.name,
          serverName: E3_BL_CORPORATION
        }
      };
      this.routerPush(route);
    },
    //  导出
    exportData() {
      exportData(this, exportCustomerHandOver);
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      if (val.id == 1) {
        this.routerPush({
          name: "customerManage/customerHandOverDetail",
          params: {
            refresh: true,
            id: row.id,
            origin: this.$route.name
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
